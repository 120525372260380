.about {
    max-width: 1000px;
    margin-top: 100px;
    padding: 0px var(--padding);
}

.about .dialog {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}

.about .dialog .thick {
    font-weight: 600;
}

.work-categories {
    position: relative;
    display: flex;
    gap: 30px;
    margin-top: 120px;
}

.work-category {
    width: 33%;
    padding: 20px;
    background-color: rgba(var(--text-color), 0.1);
    border-radius: 10px;
    backdrop-filter: blur(2px);
}
.work-category .icon {
    display: inline-block;
    width: 50%;
    margin-left: 25%;
    text-align: center;
    font-size: 60px;
    margin-bottom: 20px;
}
.work-category .description {
    font-size: 16px;
}

.work-category:nth-of-type(1) {
    border: 2px solid rgba(var(--red-color), 0.8);
}
.work-category:nth-of-type(1) .icon {
    background: rgb(var(--red-color));
    background: linear-gradient(135deg, rgb(var(--purple-color)) 0%, rgb(var(--red-color)) 50%, rgb(var(--yellow-color)) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.work-category:nth-of-type(2) {
    border: 2px solid rgba(var(--purple-color), 0.8);
}
.work-category:nth-of-type(2) .icon {
    background: rgb(var(--purple-color));
    background: linear-gradient(135deg, rgb(var(--red-color)) 0%, rgb(var(--purple-color)) 50%, rgb(var(--yellow-color)) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.work-category:nth-of-type(3) {
    border: 2px solid rgba(var(--yellow-color), 0.8);
}
.work-category:nth-of-type(3) .icon {
    background: rgb(var(--yellow-color));
    background: linear-gradient(135deg, rgb(var(--purple-color)) 0%, rgb(var(--yellow-color)) 50%, rgb(var(--red-color)) 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


@media (max-width: 800px) {
    .work-categories {
        flex-direction: column;
    }
    .work-category {
        width: auto;
    }
}