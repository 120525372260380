@font-face {
    font-family: Space;
    src: url('./css/SpaceGrotesk.ttf');
}

:root {
    --rocket-anim-delay: 0.5s;
    --rocket-anim-time: 1.8s;
    --boom-anim-time: 0.5s;
    --text-anim-time: 3s;

    --padding: 40px;
    --header-height: 70px;
    --logo-size: 40px;

    --blue-color: 68, 116, 171;
    --purple-color: 145, 152, 229;
    --red-color: 230, 100, 101;
    --orange-color: 231, 111, 81;
    --pink-color: 255, 93, 115; 
    --yellow-color: 228, 182, 55;
    --green-color: 81, 164, 138;
    --dark-color: 50, 54, 62;
    --darker-color: 40, 44, 52;
    --darkerer-color: 30, 34, 42;
    --light-color: 251, 255, 254;
    --lighter-color: 255, 255, 255;

    --bg-color: var(--darker-color);
    --bg-lighter-color: var(--dark-color);
    --bg-darker-color: var(--darkerer-color);
    --text-color: var(--light-color);
    --primary-color: var(--blue-color);
    --about-color: var(--green-color);
    --team-color: var(--orange-color);
    --projects-color: var(--yellow-color);
    --contact-color: var(--pink-color);
}

@media (max-width: 800px) {
    :root {
        --padding: 20px;
    }
}

body {
    background-color: rgb(var(--bg-color));
    color: rgb(var(--text-color));
    font-family: "Inter", sans-serif;
    overflow-x: hidden;
}

h1 {
    font-family: Space;
    font-size: 38px;
}
h1::after {
    content: "_";
}
