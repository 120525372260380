@keyframes fly {
  0% {
    top: min(150vw, 150vh);
    left: min(150vw, 150vh);
  }
  100% {
    top: 0px;
    left: 0px;
  }
}

@keyframes boom {
  0% {
    height: 0px;
    width: 0px;
  }
  100% {
    height: max(300vh, 300vw);
    width: max(300vh, 300vw);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show-content {
  0% {
    height: 0px;
    padding: 0px;
  }
  100% {
    height: auto;
  }
}

@keyframes header-blur {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(4px);
  }
}

@keyframes disappear {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes appear-slide {
  0% {
    margin-left: -10px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes star {
  0% {
    transform: translateX(0px) translateY(0px);
  }
  100% {
    transform: translateX(calc(150vw)) translateY(calc(120vh));
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}

.box-text {
  background-color: rgba(255, 255, 255, 0.08);
  padding: 0.3em 0.8em;
  border-radius: 1000px;
  font-weight: 500;
}
.box-text::after {
  content: "_";
}

.multicolored-text {
  background: rgb(var(--red-color));
  background: linear-gradient(135deg, rgb(var(--red-color)) 0%, rgb(var(--purple-color)) 50%, rgb(var(--yellow-color)) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.home {
  overflow: hidden;
}


.header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  height: var(--header-height);
  gap: 20px;
  padding: 0px var(--padding);
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  animation-name: header-blur;
  animation-duration: 0.2s;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time));
  animation-fill-mode: backwards;
  z-index: 15;
}

.header .header-logo {
  position: relative;
  height: var(--logo-size);
  width: var(--logo-size);
}

.header .header-logo .logo-animate {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  transform: rotate(-45deg);
  animation-name: fly;
  animation-delay: var(--rocket-anim-delay);
  animation-duration: var(--rocket-anim-time);
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
}

.header .header-logo .logo-animate .rocket {
  position: relative;
}

.header .header-logo .logo-animate .smoke {
  position: absolute;
  background: linear-gradient(to bottom right, rgba(var(--bg-color), 0.3) 30px, rgba(var(--orange-color), 0.3));
  height: min(200vw, 200vh);
  width: min(200vw, 200vh);
  top: 100%;
  left: 50%;
  transform-origin: top left;
  transform: rotate(45deg) skew(35deg, 35deg);
  animation-name: disappear;
  animation-duration: 1s;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time));
  animation-fill-mode: forwards;
  pointer-events: none;
}

.header .header-name {
  font-family: Space;
  font-size: 20px;
  margin-right: 40px;
  animation-name: appear-slide;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time));
  background: linear-gradient(135deg, rgba(var(--red-color), 0.7) 0%, rgba(var(--purple-color), 0.7) 50%, rgba(var(--yellow-color), 0.7) 100%);
}

.section-links {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
}
.section-links a {
  text-decoration: none;
}
.section-link {
  animation-name: appear-slide;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  box-sizing: border-box;
  cursor: pointer;
  color: rgb(var(--text-color));
  transition: all 0.3s;
}
.section-link.about {
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + 0.1s);
  border: 1px solid rgb(var(--about-color));
}
.section-link.about:hover {
  background-color: rgb(var(--about-color));
  transform: scale(1.06);
}
.section-link.team {
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + 0.2s);
  border: 1px solid rgb(var(--team-color));
}
.section-link.team:hover {
  background-color: rgb(var(--team-color));
  transform: scale(1.06);
}
.section-link.projects {
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + 0.3s);
  border: 1px solid rgb(var(--projects-color));
}
.section-link.projects:hover {
  background-color: rgb(var(--projects-color));
  transform: scale(1.06);
}
.section-link.contact {
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + 0.4s);
  border: 1px solid rgb(var(--contact-color));
}
.section-link.contact:hover {
  background-color: rgb(var(--contact-color));
  transform: scale(1.06);
}


.welcome {
  position: relative;
  width: 100vw;
  height: 100svh;
  background-color: rgb(var(--bg-lighter-color));
  z-index: 5;
}


.welcome .stars-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.stars-container .star {
  position: absolute;
  background-color: rgb(var(--text-color));
  animation-name: star;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.stars-container .star-1 {
  width: 1px;
  height: 1px;
}

.stars-container .star-2 {
  width: 2px;
  height: 2px;
}

.stars-container .star-3 {
  width: 3px;
  height: 3px;
}


.welcome .boom-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 5;
}

.boom-container .boom {
  position: absolute;
  background-color: rgb(var(--bg-color));
  height: max(300vh, 300vw);
  width: max(300vh, 300vw);
  top: calc(var(--header-height)/2);
  left: var(--padding);
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
  animation-name: boom;
  animation-iteration-count: 1;
  animation-duration: var(--boom-anim-time);
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time));
  animation-timing-function: linear;
  animation-fill-mode: backwards;
}


.welcome-body {
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  width: 100%;
  z-index: 15;
}

.welcome-body .slogan-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  animation-name: appear;
  animation-duration: 1s;
  animation-fill-mode: backwards;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + 0.1s);
}

.welcome-body .slogan {
  position: relative;
  color: rgb(var(--text-color));
  min-width: 350px;
  font-weight: 600;
  font-family: Space;
  font-size: 60px;
}

.welcome-body .slogan::after {
  content: "_";
  -webkit-text-fill-color: rgb(var(--text-color));
}

.welcome-body .scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  font-size: 20px;
  transform: translateX(-50%);
  background-color: rgba(var(--text-color), 0.1);
  padding: 10px 20px;
  border-radius: 15px;
  animation-name: appear;
  animation-duration: 1s;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + var(--text-anim-time));
  animation-fill-mode: backwards;
  backdrop-filter: blur(2px);
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation-name: show-content;
  animation-duration: 0.1s;
  animation-delay: calc(var(--rocket-anim-delay) + var(--rocket-anim-time) + var(--boom-anim-time) + var(--text-anim-time));
  animation-timing-function: steps(1);
  animation-fill-mode: backwards;
  z-index: 10;
}

.transition-banner {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 20vh 0px;
  margin-top: 160px;
  margin-bottom: 160px;
  font-size: 40px;
  background-image: linear-gradient(
      135deg, 
      rgba(var(--red-color), 0.8) 0%, 
      rgba(var(--purple-color), 0.8) 50%, 
      rgba(var(--yellow-color), 0.8) 100%
  );
  overflow: hidden;
}

.transition-banner h2 {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: rgba(var(--text-color), 0.1);
  backdrop-filter: blur(4px);
  z-index: 10;
}

.transition-banner .rocket-container {
  position: absolute;
  height: 100px;
  width: 100px;
  z-index: 5;
}
.transition-banner .rocket-container:nth-of-type(1) {
  left: 30%;
  bottom: 50px;
}
.transition-banner .rocket-container:nth-of-type(2) {
  right: 30%;
  top: 50px;
}

@media (max-width: 800px) {

  .welcome-body .slogan {
    text-align: center;
    min-width: 0px;
    width: calc(100% - 40px);
    font-size: 40px;
  }

  .section-links {
    display: none;
    font-size: 13px;
  }

  .section-links-inner {
    gap: 10px;
    padding: 10px 15px;
    border-radius: 15px;
  }

}