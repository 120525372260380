@keyframes appear {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes appear-icon {
    0% {opacity: 0; transform: translateY(20px) translateX(-50%);}
    100% {opacity: 1; transform: translateY(00px) translateX(-50%);}
}

@keyframes bounce {
    0% {transform: translateY(0px);}
    50% {transform: translateY(-30px);}
    90% {transform: translateY(0px);}
}

.contact {
    position: relative;
    display: block;
    margin: 0px auto;
    max-width: 1000px;
    padding: 0px var(--padding);
    margin-bottom: 200px;
}

.contact .dialog {
    text-align: center;
    font-size: 30px;
    font-weight: 500;
}

.contact .dialog .thick {
    font-weight: 600;
}

.contact form {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 25px;
    background-color: rgb(var(--bg-darker-color));
    padding: 30px 40px;
    border-radius: 20px;
    margin-top: 60px;
    overflow: hidden;
}

.contact form .loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    animation-name: appear;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    z-index: 10;
}

.contact form .loading .ball {
    position: absolute;
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;
    animation-name: bounce;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
.contact form .loading .ball:nth-of-type(1) {
    left: calc(50% - 38px);
    background-color: rgb(var(--red-color));
}
.contact form .loading .ball:nth-of-type(2) {
    left: calc(50% - 8px);
    background-color: rgb(var(--purple-color));
    animation-delay: 0.15s;
}
.contact form .loading .ball:nth-of-type(3) {
    left: calc(50% + 22px);
    background-color: rgb(var(--yellow-color));
    animation-delay: 0.3s;
}
.contact form .loading .description {
    position: absolute;
    top: calc(50% + 25px);
    left: 50%;
    font-size: 20px;
    font-weight: 600;
    transform: translateX(-50%);
}


.contact form .submitted {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
}
.contact form .submitted .icon {
    position: absolute;
    top: calc(50% - 100px);
    left: 50%;
    transform: translateX(-50%);
    font-size: 80px;
    animation-name: appear-icon;
    animation-fill-mode: backwards;
    animation-duration: 0.4s;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
}
.contact form .submitted .description {
    position: absolute;
    top: calc(50%);
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    animation-name: appear;
    animation-fill-mode: backwards;
    animation-duration: 0.4s;
    animation-delay: 0.4s;
    animation-iteration-count: 1;
}


.contact form label {
    display: block;
    color: rgba(var(--text-color));
    font-weight: 500;
    margin-bottom: 3px;
}

.contact form input {
    width: min(400px, 100%);
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: rgb(var(--text-color));
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: rgb(var(--bg-lighter-color));
    transition: all 0.4s;
}

.contact form textarea {
    min-width: 100%;
    min-height: 200px;
    resize: none;
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: rgb(var(--text-color));
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid transparent;
    background-color: rgb(var(--bg-lighter-color));
    transition: all 0.4s;
}

.contact form button {
    padding: 10px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: rgb(var(--text-color));
    border-radius: 10px;
    border: none;
    background-image: linear-gradient(
        135deg, 
        rgba(var(--purple-color), 0.8) 0%, 
        rgba(var(--red-color), 0.8) 50%, 
        rgba(var(--yellow-color), 0.8) 100%
    );
    background-position: 0px 0px;
    cursor: pointer;
    background-size: 150px;
    background-position: center;
    transition: all 0.3s;
}
.contact form button:hover {
    background-size: 200px;
    transform: scale(1.05);
}

.contact form .form-address input:focus {
    outline: none;
    border: 1px solid rgb(var(--red-color));
}

.contact form .form-subject input:focus {
    outline: none;
    border: 1px solid rgb(var(--purple-color));
}

.contact form .form-message textarea:focus {
    outline: none;
    border: 1px solid rgb(var(--yellow-color));
}


@media (max-width: 800px) {
    .contact form {
        padding: 20px 15px;
    }
}