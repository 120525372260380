.rocket {
    height: 100%;
    width: 100%;
}

.rocket svg {
    height: 100%;
    width: 100%;
}

.rocket .rocket-window, 
.rocket .rocket-fuselage,
.rocket .rocket-engine {
    fill: rgb(var(--primary-color));
}